import request from 'superagent';

export const API_HOST = 'https://api.whatsthetolloni66.com'

export const getToll = async (entry, exit) => {
  const response = await request.get(`${API_HOST}/${entry}/${exit}`);
  return JSON.parse(response.text);
};

export const getLocation = async (loc) => {
  const response = await request.get(`${API_HOST}/${loc}`);
  return JSON.parse(response.text);
};

export const getLocations = async () => {
  const response = await request.get(`${API_HOST}/locations`);
  return JSON.parse(response.text);
};
