import React from 'react';
import { Router, Route, Switch } from 'react-router';
import history from './history';
import Main from './routes/Main';

const App = () => {
  return (
    <Router history={history}>
      <div className="text-center">
        <h1>{'what\'s the toll on i66?'}</h1>
        <p>freshly scraped from <a href="http://vai66tolls.com">vai66tolls.com</a></p>
      </div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/:entry" component={Main} />
        <Route path="/:entry/:exit" component={Main} />
      </Switch>
    </Router>
  );
};

export default App;
