import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getLocations, getToll } from '../api';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingLocations: false,
      loadingToll: false,
      locations: [],
      exitOptions: [],
      entry: null,
      exit: null,
      toll: '$ -',
      time: null,
    };
  }

  async componentDidMount() {
    await this.getLocations();
    const { entry, exit } = this.props.match.params;
    if (entry) {
      this.onSelectEntry(this.state.locations.filter(loc => loc.id === entry)[0], false);
    }
    if (exit) {
      this.onSelectExit(this.state.locations.filter(loc => loc.id === exit)[0], false);
    }
  }

  getLocations = async () => {
    this.setState({ loadingLocations: true });
    const locations = await getLocations();
    this.setState({ loadingLocations: false, locations });
  }

  getToll = async () => {
    const { entry, exit } = this.state;
    this.setState({ loadingToll: true });
    const { toll, time } = await getToll(entry.id, exit.id);
    this.setState({ loadingToll: false, toll, time });
  }

  onSelectEntry = (entry, updateUrl = true) => {
    const { locations } = this.state;
    const exitIds = [...entry.eastbound, ...entry.westbound];
    const exitOptions = exitIds.map((id) => {
      return locations.filter(loc => loc.id === id)[0];
    });
    let updatedUrl;
    if (this.state.exit && exitIds.includes(this.state.exit.id)) {
      this.setState({ entry, exitOptions }, this.getToll);
      updatedUrl = `/${entry.id}/${this.state.exit.id}`;
    } else {
      this.setState({ entry, exit: null, exitOptions, toll: '$-' });
      updatedUrl = `/${entry.id}`;
    }
    if (updateUrl) {
      this.props.history.replace(updatedUrl);
    }
  };

  onSelectExit = (exit, updateUrl = true) => {
    this.setState({ exit }, this.getToll);
    if (updateUrl) {
      this.props.history.replace(`/${this.state.entry.id}/${exit.id}`)
    };
  };

  render() {
    const {
      loadingToll,
      locations,
      exitOptions,
      entry,
      exit,
      toll,
      time,
    } = this.state;
    return (
			<React.Fragment>
				<div className="row m-l-0 m-r-0 p-l-5 p-r-5">
					<div className="col-xs-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
						<div>from</div>
						<Select
							className="m-b-5"
							placeholder="Select entry"
							options={locations}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
							onChange={this.onSelectEntry}
							value={entry}
              isSearchable={false}
						/>
						<div>to</div>
						<Select
							placeholder="Select exit"
							className="m-b-10"
							options={exitOptions}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
							onChange={this.onSelectExit}
							value={exit}
							isDisabled={!entry}
              isSearchable={false}
						/>
					</div>
				</div>
				<div className="row m-l-0 m-r-0">
					<div className="col-xs-12 col-sm-4 offset-sm-4 col-md-4 offset-md-4">
						<div className="text-center">
							<h1 className="m-b-5">
								{toll}
								{ loadingToll &&
									<span className="m-l-10 m-t-10 fs-12">
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</span>
								}
							</h1>
              {time && <p>at {time}</p>}
						</div>
					</div>
				</div>
			</React.Fragment>
    );
  }
};

Main.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Main;
